import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { Token } from '../models/token.model';
import { IUserRegister, UserLogin } from '../models/userCore.model';
import {
  AuthState,
  changePasswordAction,
  ErrorState,
  forgotPasswordAction,
  getMeAction,
  loginAction,
  loginAppleAction,
  loginFacebookAction,
  loginGoogleAction,
  logoutAction,
  refreshTokenAction,
  registerAction,
  selectAuthError,
  selectAuthState,
  selectToken,
  selectUser,
  updateMeAction,
} from '../store/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly user$ = this.store.select(selectUser);
  private readonly token$ = this.store.select(selectToken);
  private readonly error$ = this.store.select(selectAuthError);
  readonly authState$ = this.store.select(selectAuthState);

  private errors: string[] = [];

  constructor(private readonly store: Store<{ auth: AuthState }>, private readonly router: Router, private readonly afAuth: AngularFireAuth) {}

  login(userLogin: UserLogin) {
    this.store.dispatch(loginAction({ userLogin }));
  }

  profile() {
    this.store.dispatch(getMeAction());
  }

  logout() {
    return this.store.dispatch(logoutAction());
  }

  register(userLogin: IUserRegister) {
    return this.store.dispatch(registerAction({ userLogin }));
  }

  refreshToken(token: Token) {
    return this.store.dispatch(refreshTokenAction({ token: token.refreshToken }));
  }

  updateMe() {
    this.store.dispatch(updateMeAction());
  }

  loginWithGoogle() {
    this.store.dispatch(loginGoogleAction());
  }

  loginWithFacebook() {
    this.store.dispatch(loginFacebookAction());
  }

  loginWithApple() {
    this.store.dispatch(loginAppleAction());
  }

  statusUser() {
    return this.user$;
  }

  statusToken() {
    return this.token$;
  }

  statusError() {
    return this.error$.pipe(filter((error): error is ErrorState => !!error));
  }

  forgotPassword(username: string) {
    this.store.dispatch(forgotPasswordAction({ username }));
  }

  changePassword(body: { oldPassword: string; newPassword: string }) {
    this.store.dispatch(changePasswordAction(body));
  }
}
