import { LanguagesEnum } from 'src/app/main/shared/enums/app-languages.enum';

interface iUser<T = any, W = any> {
  id: string;
  email: string;
  name: string;
  surname: string;
  level: number;
  permissions: string[];
  data?: T; //data extra dell utente
  company: iCompany<W>;
}

export interface iCompany<W = any> {
  level: number;
  name: string;
  permissions: null;
  id: string;
  data?: W;
}

export class UserCore<T = any, W = any> implements iUser<T, W> {
  id: string = '';
  email: string = '';
  name: string = '';
  surname: string = '';
  level: number = 0;
  data?: T;
  company: iCompany<W>;
  permissions: string[];

  constructor(user: iUser<T, W>) {
    this.email = user.email;
    this.id = user.id;
    this.level = user.level;
    this.company = user.company;
    this.name = user.name;
    this.surname = user.surname;
    this.permissions = user.permissions;
    this.data = user.data;
  }

  // @todo -> metodo per gestire data nello store
}

interface iUserLogin {
  email: string;
  password: string;
}

export class UserLogin {
  email: string = '';
  password: string = '';

  constructor(user?: iUserLogin) {
    this.email = user?.email ?? '';
    this.password = user?.password ?? '';
  }
}

export interface IUserRegister {
  name: string;
  surname: string;
  email: string;
  password: string;
  language: LanguagesEnum;
}
