<ng-container *ngIf="!isProfile; else botImage">
  <!-- <i
    *ngIf="type === 'primeng'; else materialIcon"
    [class]="'pi ' + iconName + ' ' + class"
    [pTooltip]="tooltip"
    [tooltipPosition]="tooltipPosition"
  ></i>
  <ng-template #materialIcon>
    <span
      style="font-size: 1.5rem"
      [class]="'small-icon material-symbols-outlined ' + class"
      [pTooltip]="tooltip"
      [tooltipPosition]="tooltipPosition"
    >
      {{ iconName }}
    </span>
  </ng-template> -->

  @switch (type) { @case ('primeng') {
  <i [class]="'pi ' + iconName + ' ' + class" [pTooltip]="tooltip" [tooltipPosition]="tooltipPosition"></i>
  } @case ('material') {
  <span
    style="font-size: 1.5rem; vertical-align: middle"
    [class]="'small-icon ' + class"
    [ngClass]="{ 'material-symbols-outlined': !filled, 'material-icons': filled }"
    [pTooltip]="tooltip"
    [tooltipPosition]="tooltipPosition"
  >
    {{ iconName }}
  </span>
  } @case ('image') {
  <span
    style="font-size: 1.5rem"
    [class]="'small-icon ' + class"
    [ngClass]="{ 'material-symbols-outlined': !filled, 'material-icons': filled }"
    [pTooltip]="tooltip"
    [tooltipPosition]="tooltipPosition"
  >
    <img style="width: 1.5rem; fill: var(--primary-color)" class="" [src]="iconName" />
  </span>

  } @default {
  <span
    style="font-size: 1.5rem"
    [class]="'small-icon ' + class"
    [ngClass]="{ 'material-symbols-outlined': !filled, 'material-icons': filled }"
    [pTooltip]="tooltip"
    [tooltipPosition]="tooltipPosition"
  >
    {{ iconName }}
  </span>
  } }
</ng-container>
<ng-template #botImage>
  <img style="width: 28px; aspect-ratio: 1" class="border-1 border-black-alpha-30 border-solid border-circle" [src]="profileUrl" />
</ng-template>
