import { Component, Input } from '@angular/core';
import { UsersService } from 'src/app/main/services/users.service';

@Component({
  selector: 'custom-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  @Input() type: 'primeng' | 'material' | 'image' = 'material';
  @Input() iconName: string = '';
  @Input() tooltip?: string = '';
  @Input() tooltipPosition?: 'right' | 'left' | 'top' | 'bottom';
  @Input() isProfile: boolean = false;
  @Input() filled?: boolean = false;

  @Input() class: string = '';

  profileUrl?: string = '';

  constructor(private readonly userService: UsersService) {
    this.userService.getUser$().subscribe({
      next: (user) => (this.profileUrl = user?.image || 'assets/layout/images/avatar.png'),
    });
  }
}
