import { UserCore } from 'src/app/core/models/userCore.model';
import { LanguagesEnum } from '../enums/app-languages.enum';

/**Model User */
export class User<T = any, W = any> extends UserCore<T, W> {
  image: string = '';
  username: string = '';
  language?: LanguagesEnum;
  description?: string = '';

  /** @ignore */
  constructor(user: User<T, W>) {
    super(user);

    this.image = user.image;
    this.username = user.username;
    this.language = user.language;
    this.description = user.description;
  }
}

export interface ICompanyUser {
  companyId: string;
  id: string;
  username: string;
  anagrafica: {
    name: string;
    surname: string;
  };
}

export class CompanyUser {
  companyId: string = '';
  id: string = '';
  username: string = '';
  anagrafica: {
    name: string;
    surname: string;
  } = {
    name: '',
    surname: '',
  };

  constructor(res?: ICompanyUser) {
    if (res) {
      this.companyId = res.companyId;
      this.id = res.id;
      this.username = res.username;
      this.anagrafica = res.anagrafica;
    }
  }
}
