import { v4 } from 'uuid';
import { CompanyUser, ICompanyUser } from '../models/user.model';

export const MOCK_USERS: ICompanyUser[] = [
  {
    id: v4(),
    username: 'User1',
    companyId: v4(),
    anagrafica: {
      name: 'User',
      surname: 'Number 1',
    },
  },
  {
    id: v4(),
    username: 'User2',
    companyId: v4(),
    anagrafica: {
      name: 'User',
      surname: 'Number 2',
    },
  },
  {
    id: v4(),
    username: 'User3',
    companyId: v4(),
    anagrafica: {
      name: 'User',
      surname: 'Number 3',
    },
  },
];
